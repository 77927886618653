import React from 'react'
import { Button, message, Modal } from 'antd'
import newsServices from '../../../services/newsServices'

interface ModalRemoveHotBlogProps {
  blogId: string | null;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  getHotNewsList: any;
}

function ModalRemoveHotBlog(props: ModalRemoveHotBlogProps) {
  const {
    isOpen, setIsOpen, blogId, getHotNewsList,
  } = props
  const handleConfirmRemove = async () => {
    if (!blogId) return
    try {
      await newsServices.pickHotNews({
        ids: [blogId],
        status: false
      })
      message.success('Success')
      getHotNewsList()
    } catch (e) {
      message.error('Failed. Please Try Again')
    }
    setIsOpen(false)
  }

  return (
    <Modal
      title="Remove Hot News?"
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      centered
      footer={[
        <Button key="back" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          className="btn-green"
          onClick={handleConfirmRemove}
        >
          Confirm
        </Button>,
      ]}
      width={456}
    >
      <p>
        Are you sure you want to remove this News from your Hot News list?
      </p>
    </Modal>
  )
}

export default ModalRemoveHotBlog
