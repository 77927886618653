import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import newsServices from '../../services/newsServices';
import TagInput from '../../components/TagInput';
import moment from 'moment';
import slugify from 'slugify';
import ImageArrayUpload from '../../components/ImageArrayUpload';
import CKEditorCustom from '../../components/CKEditorCustom';
import { DEFAULT_IMAGE, ERROR_MESSAGE } from '../../constants/common';

interface FormDetailNewsProps {
  blogForm: any;
  preview: boolean;
  setPreview: (value: boolean) => void;
  statusHandle: any;
  selectedId: string | null;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  detailData: any;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setLoading: any;
}

function FormDetailNews(props: FormDetailNewsProps) {
  const {
    blogForm,
    preview,
    setPreview,
    statusHandle,
    selectedId,
    isEditing,
    setIsEditing,
    detailData,
    setIsOpen,
    setLoading
  } = props;

  const [tags, setTags] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const [thumbnail, setThumbnail] = useState<any>([]);
  const [title, setTitle] = useState('');

  const resetForm = () => {
    blogForm.resetFields();
    setTags([]);
    setEditorContent('');
    setThumbnail([]);
    setIsEditing(false);
  };

  useEffect(() => {
    if (title) {
      blogForm.setFieldValue(
        'slug',
        slugify(`${title.toLowerCase()} ${Date.now()}`, '-')
      );
      blogForm.validateFields(['slug']);
    }
  }, [title]);

  const editBlog = async () => {
    setLoading(true);
    if (!selectedId) {
      return;
    }
    try {
      const blog = {
        ...blogForm.getFieldsValue(true)
      };
      if (detailData?.status !== statusHandle) {
        blog.status = statusHandle;
      }
      await newsServices.editNews(selectedId, blog);
      setIsEditing(false);
      setLoading(false);
      message.success('Edit successful!');
    } catch {
      message.error(ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const createBlog = async () => {
    setLoading(true);
    try {
      const blog = {
        ...blogForm.getFieldsValue(true),
        status: statusHandle
      };
      await newsServices.createNews(blog);
      setIsEditing(false);
      resetForm();
      setLoading(false);
      setIsOpen(false);
      message.success('Creation successful!');
    } catch {
      message.error(ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const onFinishCreateForm = async () => {
    if (selectedId) {
      await editBlog();
    } else {
      await createBlog();
    }
  };

  useEffect(() => {
    if (!thumbnail?.length) {
      blogForm.setFieldValue('thumbnail', null);
    } else {
      blogForm.setFieldValue('thumbnail', thumbnail?.[0]?.url);
      blogForm.validateFields(['thumbnail']);
    }
  }, [thumbnail]);

  useEffect(() => {
    if (!tags?.length) {
      blogForm.setFieldValue('tags', '');
    } else {
      blogForm.setFieldValue('tags', tags);
    }
    blogForm.validateFields(['tags']);
  }, [tags]);

  useEffect(() => {
    blogForm.setFieldValue('content', editorContent);
    blogForm.validateFields(['content']);
  }, [editorContent]);

  useEffect(() => {
    if (detailData?.title) {
      setTags(detailData?.tags?.length ? detailData?.tags : []);
      setEditorContent(detailData?.content || null);
      setThumbnail(
        detailData?.thumbnail ? [{ url: detailData?.thumbnail }] : []
      );
    } else {
      resetForm();
    }
  }, [detailData]);

  return (
    <>
      <Form
        layout="vertical"
        disabled={!!(selectedId && !isEditing)}
        form={blogForm}
        onFinish={onFinishCreateForm}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please input title'
            }
          ]}
        >
          <Input
            placeholder="Input title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Form.Item>

        <div className="form-divide">
          <div className="child-form">
            <Form.Item
              label="Tag"
              name="tags"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Vui lòng thêm tag',
            //   },
            // ]}
            >
              <TagInput
                tags={tags}
                setTags={setTags}
                disabled={!!(selectedId && !isEditing)}
              />
            </Form.Item>
          </div>
          <div className="child-form">
            <Form.Item
              label="Slug"
              name="slug"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Vui lòng nhập slug',
            //   },
            // ]}
            >
              <Input
                placeholder="Enter a title to automatically generate a slug."
                disabled
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          rules={[
            {
              required: true,
              message: 'Please input thumbnail'
            }
          ]}
        >
          <ImageArrayUpload
            maxLength={1}
            fileList={thumbnail}
            setFileList={setThumbnail}
            isDisable={!!(selectedId && !isEditing)}
          />
        </Form.Item>
        <Form.Item
          label="Short description"
          name="short_description"
          rules={[
            {
              required: true,
              message: 'Please short description'
            }
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Main content"
          name="content"
          rules={[
            {
              required: true,
              message: 'Please input main content'
            }
          ]}
        >
          <CKEditorCustom
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            isReadOnly={!!(selectedId && !isEditing)}
          />
        </Form.Item>
      </Form>
      <Modal
        centered
        open={preview}
        onOk={() => setPreview(false)}
        onCancel={() => setPreview(false)}
        width={1176}
        footer={null}
      >
        <div className="container pt-4 preview-block">
          {thumbnail?.[0]?.url && (
            <img
              className="thumnail-img object-cover aspect-video"
              src={thumbnail?.[0]?.url || DEFAULT_IMAGE}
              alt="thumbnail"
            />
          )}
          <div className="flex gap-5 justify-between my-8">
            <div className="name-section flex gap-3">
              <img
                src={detailData?.created_user?.avatar_url || DEFAULT_IMAGE}
                alt="avatar"
                className="w-12 h-12 rounded-full"
              />
              <div className="information flex flex-col">
                <div className="name font-semibold">
                  {detailData?.created_user?.full_name}
                </div>
                <div className="published-date text-[#969696] text-sm">
                  Posted day
                  {detailData?.publish_at
                    ? ` ${moment(detailData?.publish_at).format('DD/MM/YYYY')}`
                    : ': Not posted yet'}
                </div>
              </div>
            </div>
            <div className="tags-section flex justify-end flex-1 flex-wrap items-center gap-2">
              {(tags || []).map((tag, index) => (
                <div
                  key={index}
                  className="tag-item bg-[#f6f6f6] px-3 text-[#585757] text-xl rounded-[40px] leading-[40px]"
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>
          {detailData?.title && (
            <h1 className="font-bold text-4xl">{detailData?.title}</h1>
          )}
          {detailData?.short_description && (
            <p style={{ marginBottom: '20px' }}>
              <b>{detailData?.short_description}</b>
            </p>
          )}
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: editorContent }}
          />
        </div>
      </Modal>
    </>
  );
}

export default FormDetailNews;
