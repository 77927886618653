import {
  Button, Checkbox, Drawer, Input, message, Table,
  TableProps,
} from 'antd'
import React, { useEffect, useState } from 'react'
import newsServices from '../../../services/newsServices'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import useDebounce from '../../../hooks/useDebounce'
import { NEWS_STATUS } from '../../../constants/common'

interface HotBlogManageDrawerProps {
  openSidebar: any;
  handleCloseSidebar: any;
}

const HotBlogManageDrawer = (props: HotBlogManageDrawerProps) => {
  const { openSidebar, handleCloseSidebar } = props
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedRowRecord, setSelectedRowRecords] = useState<any[]>([])
  const [keyword, setKeyword] = useState('')
  const inputSearch = useDebounce(keyword, 500);


  const fetchData = async () => {
    setLoading(true)
    try {
      const res = await newsServices.getAllNews({
        limit: 50,
        page: 1,
        order_by: 'created_at',
        sort_by: 'desc',
        statuses: [NEWS_STATUS.SHOW],
        is_hot: false,
        keyword: inputSearch,
      })
      const existedIds = selectedRowRecord.map((item: any) => item?.id) ?? []
      const mappingData = (res.data?.data?.items || [])
        .map((blog: any) => ({
          id: blog?.id,
          title: blog?.title,
          full_name: blog?.created_user?.full_name,
          checked: false,
        }))
        .filter(
          (x: any) => !existedIds.includes(x?.id),
        )
      setData([...selectedRowRecord, ...mappingData] as any)
    } catch (e) {
      message.error('Đã có lỗi trong quá trình xử lý. Vui lòng thử lại sau!')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (openSidebar) {
      fetchData()
    }
  }, [inputSearch, openSidebar])

  useEffect(() => {
    if (!openSidebar) {
      setSelectedRowRecords([])
      setKeyword('')
    }
  }, [openSidebar])

  const onSelectChange = (e: CheckboxChangeEvent, selectedRow: any) => {
    const index = data.findIndex((x: any) => x.id === selectedRow.id)
    if (e?.target?.checked) {
      if (!selectedRowRecord.some((item: any) => item?.id === selectedRow?.id)) {
        setSelectedRowRecords((prevDataArray: any) => [
          ...prevDataArray,
          { ...selectedRow, checked: true },
        ])
        if (index !== -1) {
          const newData = [...data]
          newData[index].checked = true
          setData(newData)
        }
      }
    } else {
      const filteredArray = selectedRowRecord.filter(
        (item: any) => item.id !== selectedRow?.id,
      )
      setSelectedRowRecords(filteredArray)
      if (index !== -1) {
        const newData = [...data]
        newData[index].checked = false
        setData(newData)
      }
    }
  }

  const columns: TableProps<any>['columns']= [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 500,
      render: (title: any) => (
        <div
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {title}
        </div>
      ),
    },
    {
      title: 'Creator',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: '',
      key: 'checked',
      render: (record: any) => (
        <>
          <Checkbox
            checked={record?.checked}
            onChange={(e) => onSelectChange(e, record)}
          />
        </>

      ),
      fixed: 'right',
    },
  ]

  const handleChange = (e: { target: { value: any } }) => {
    setKeyword(e.target.value)
  }

  const handleCancelSettingModal = () => {
    handleCloseSidebar(false)
  }

  const addHotBlog = async () => {
    try {
      await newsServices.pickHotNews({
        ids: [...selectedRowRecord.map((item: any) => item.id)],
        status: true,
      })
      message.success('Success')
      handleCancelSettingModal()
    } catch (e) {
      message.error('Failed. Please try again')
    }
  }

  const handleOkSettingModal = () => {
    if (selectedRowRecord && selectedRowRecord.length) {
      addHotBlog()
    } else {
      message.info('Vui lòng chọn ít nhất một bài viết!')
    }
  }

  return (
    <Drawer
      title="Setup Hot News"
      placement="right"
      open={openSidebar}
      onClose={handleCancelSettingModal}
      width={800}
      className="category-modal drawer-common"
      extra={(
        <div className="drawer-extra">
          <Button
            onClick={handleCancelSettingModal}
          >
            Cancel
          </Button>
          <Button className='ml-2' type="primary" onClick={handleOkSettingModal}>
            Save
          </Button>
        </div>
      )}
      footer={null}
    >
      <div className="search-cont">
        <Input
          placeholder="Search"
          className="search"
          onChange={handleChange}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        className="main-table"
        pagination={false}
        loading={loading}
      />
    </Drawer>
  )
}

export default HotBlogManageDrawer
