import React, { useEffect, useMemo, useState } from 'react';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Switch, Table, TableProps } from 'antd';
import DrawerNews from '../DrawerNews';
import {
  DEFAULT_PAGINATE_SIZE,
  ERROR_MESSAGE,
  NEWS_STATUS,
  NEWS_STATUS_TO_TEXT
} from '../../../constants/common';
import newsServices from '../../../services/newsServices';
import useDebounce from '../../../hooks/useDebounce';
import moment from 'moment';
import FilterNews from '../FilterNews';
import Title from 'antd/es/typography/Title';
import ModalRemoveHotBlog from './ModalRemoveHotBlog';
import HotBlogManageDrawer from './HotBlogManageDrawer';

const HotNewsComponent: React.FC = () => {
  const [blogList, setBlogList] = useState([]);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [queryParams, setQueryParams] = useState({
    limit: DEFAULT_PAGINATE_SIZE,
    create_started_at: null,
    create_ended_at: null,
    statuses: null,
    created_by: []
  });
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState(''); // Value in input field
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const inputSearch = useDebounce(inputText, 500);

  const [selectedId, setSelectedId] = useState(null); // Id blog selected
  const [isOpenModalRemove, setIsOpenModalRemove] = useState(false); // Id blog selected


  async function getHotNewsList() {
    setLoading(true);
    try {
      const apiRs = await newsServices.getAllNews({
        ...queryParams,
        order_by: 'pick_hot_date',
        sort_by: 'desc',
        page,
        limit: 50,
        keyword: inputSearch || null,
        statuses: null,
        is_hot: true,
        create_started_at: queryParams?.create_started_at
          ? moment(queryParams?.create_started_at).format('YYYY-MM-DD')
          : null,
        create_ended_at: queryParams?.create_ended_at
          ? moment(queryParams?.create_ended_at).format('YYYY-MM-DD')
          : null
        // created_by: queryParams?.created_by?.join(',') || null
      });
      setBlogList(
        (apiRs?.data?.data?.items || []).map((item: any) => ({
          ...item,
          key: item?.id
        }))
      );
      setTotalCount(apiRs?.data?.data?.meta?.total);
    } catch {
      setBlogList([]);
      setTotalCount(0);
      message.error(ERROR_MESSAGE);
    }
    setLoading(false);
  }

  const handleRowClick = (record: any) => {
    setIsOpenDrawer(true);
    setSelectedId(record?.id);
  };

  const paginationConfig = useMemo(
    () => ({
      defaultPageSize: DEFAULT_PAGINATE_SIZE, // Set initial page size
      onChange: (pageNumber: number) => {
        setPage(pageNumber);
      },
      showSizeChanger: false,
      total: totalCount,
      current: parseInt(String(page), 10),
      hideOnSinglePage: true
    }),
    [totalCount, page]
  );

  useEffect(() => {
    if (!isOpenDrawer) {
      getHotNewsList();
    }
  }, [queryParams, page, inputSearch, isOpenDrawer]);

  const columns: TableProps<any>['columns'] = [
    {
      title: '',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 56,
      render: (thumbnail: string) => (
        <img
          src={thumbnail || '/images/common/default-blog-thumbnail.svg'}
          alt="thumbnail"
          style={{
            width: 32,
            height: 32,
            objectFit: 'cover',
            borderRadius: 4
          }}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = '/images/common/default-blog-thumbnail.svg';
          }}
        />
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 400,
      key: 'title',
      ellipsis: true
    },
    {
      title: 'Created user',
      dataIndex: 'created_user',
      key: 'created_user',
      ellipsis: true,
      // eslint-disable-next-line camelcase
      render: (created_user: any) => (
        // eslint-disable-next-line camelcase
        <span className="text-blue">{created_user?.full_name}</span>
      )
    },
    {
      title: 'Last update',
      dataIndex: 'updated_at',
      width: 160,
      key: 'updated_at',
      // eslint-disable-next-line camelcase
      render: (updated_at: any) =>
        moment(updated_at).format('HH:mm - DD/MM/YYYY')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 120,
      render: (status: NEWS_STATUS) => (
        <span className={`tag tag-${status.toLocaleLowerCase()}`}>
          {NEWS_STATUS_TO_TEXT[status]}
        </span>
      )
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: 100,
      render: (_: any, record: any) => {
        return (
          <Button
            type='primary'
            onClick={(e) => openModalRemove(record?.id)}
          >
            Remove
          </Button>
        )
      }
    }
  ];

  const openModalRemove = (id: any) => {
    setSelectedId(id)
    setIsOpenModalRemove(true)
  }

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      className="manage-bonus-point-page page-tab-card"
    >
      <div
        className="breadcrumb-bc"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Title className="!mb-4" level={3}>
          News Management
        </Title>
      </div>

      <div className="main-content">
        <div className="flex items-center justify-between gap-2 mt-4 mb-8">
          <Button
            icon={<PlusOutlined />}
            className="btn-green"
            onClick={() => {
              setIsOpenDrawer(true);
              setSelectedId(null);
            }}
          >
            Pickup Hot News
          </Button>
        </div>

        <Table
          className="main-table mt-4"
          columns={columns}
          dataSource={blogList}
          pagination={paginationConfig}
          rowClassName={() => 'cursor-pointer'}
          loading={loading}
        />
      </div>

      <FilterNews
        isOpen={isOpenFilter}
        setIsOpen={setIsOpenFilter}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPage={setPage}
      />

      <ModalRemoveHotBlog
        isOpen={isOpenModalRemove}
        setIsOpen={setIsOpenModalRemove}
        blogId={selectedId}
        getHotNewsList={getHotNewsList}
      />

      <HotBlogManageDrawer
        openSidebar={isOpenDrawer}
        handleCloseSidebar={() => setIsOpenDrawer(false)}
      />

    </div>
  );
};

export default HotNewsComponent;
