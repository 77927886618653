import React from 'react';
import Sider from 'antd/es/layout/Sider';
import { Menu } from 'antd';
import { TwitchOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
const SiderComponent: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Sider collapsible className="h-full overflow-auto">
      <div className="demo-logo-vertical" />
      <Menu
        className="m-3"
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1-1']}
        defaultOpenKeys={['sub1']}
      >
        <Menu.SubMenu key="sub1" icon={<TwitchOutlined />} title="News">
          <Menu.Item key="1-1" icon={<UnorderedListOutlined />}>
            <Link to="/news">News List</Link>
          </Menu.Item>
          <Menu.Item key="1-2" icon={<UnorderedListOutlined />}>
            <Link to="/news-hot">Hot News</Link>
          </Menu.Item>
        </Menu.SubMenu>

        {/* <Menu.SubMenu key="sub1" icon={<VideoCameraOutlined />} title="nav 2">
          <Menu.Item key="1-1">
            <Link to="/user/child1">Child 1-1</Link>
          </Menu.Item>
          <Menu.Item key="1-2">
            <Link to="/user/child2">Child 1-2</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="2" icon={<UploadOutlined />}>
          <Link to="/upload">Nav 3</Link>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default SiderComponent;
