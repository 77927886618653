import axiosInstance from '../axios/axiosInstance';
import {
  createNewsBody,
  GetAllNewParams,
  pickHotNewsBody
} from '../type/request';

const newsServices = {
  getAllNews(params: GetAllNewParams) {
    return axiosInstance.get('/v1/news', { params });
  },

  createNews(data: createNewsBody) {
    return axiosInstance.post('/v1/news', data);
  },

  getDetailNews(id: string) {
    return axiosInstance.get(`/v1/news/${id}`);
  },

  deleteNews(id: string) {
    return axiosInstance.delete(`/v1/news/${id}`);
  },

  editNews(id: string, data: any) {
    return axiosInstance.put(`/v1/news/${id}`, data);
  },

  pickHotNews(body: pickHotNewsBody) {
    return axiosInstance.post('/v1/news/pick-hot-news', body);
  }
};

export default newsServices;
